const rootUrl = 'https://test.api.portal.mdcn.gov.ng';
const remitaVerificationCallBackUrl = 'https://test.cpd.portal.mdcn.gov.ng/application-callback';
const remitaVisitationCallBackUrl = 'https://test.cpd.portal.mdcn.gov.ng/visitation-callback';
const remitaReApplicationCallBackUrl = 'https://test.cpd.portal.mdcn.gov.ng/re-accreditation-callback';
const remitaReVisitationCallBackUrl = 'https://test.cpd.portal.mdcn.gov.ng/re-visitation-callback';
const remitaRenewalCallbackUrl = 'https://test.cpd.portal.mdcn.gov.ng/renewal-callback';
const portalUrl = 'https://test.portal.mdcn.gov.ng';
// user module Urls
const createSignupUrl = rootUrl+'/cpd-register';
const cpdLoginUrl = rootUrl+'/cpd-login';
const cpdProfileUrl = rootUrl+'/get-cpd-profile';
const uploadCpdPhotoUrl = rootUrl+'/upload-cpd-photo';
const changePasswordUrl = rootUrl+'/change-cpd-password';
const getCpdNotificationsUrl = rootUrl+'/get-cpd-notifications';
const deleteCpdNotificationUrl = rootUrl+'/delete-cpd-notification';
const locationsUrl = rootUrl+'/get-locations';
const updatePasswordUrl = rootUrl+'/update/password';

// Parameter module Urls
const getSiteParameterUrl = rootUrl+'/get-parameter-value';
// Payment Urls
const getVerificationPaymentUrl = rootUrl+'/cpd-verification-payment';
const getReAccreditationPaymentUrl = rootUrl+'/cpd-re-accreditation-payment';
const generateVerificationRrrUrl = rootUrl+'/generate-assessment-rrr';
const saveReAccreditationTransactionUrl = rootUrl+'/save-cpd-reaccreditation-transaction';
const reReAccreditationRrrStatusUrl = rootUrl+'/cpd-re-accreditation-rrr';
const getCpdPaymentsUrl = rootUrl+'/get-cpd-payments';
const getVisitationPaymentUrl = rootUrl+'/cpd-visitation-payment';
const saveVisitationTransactionUrl = rootUrl+'/save-cpd-visitation-transaction';
const visitationRrrStatusUrl = rootUrl+'/cpd-visitation-rrr';
const getTempRenewalPaymentUrl = rootUrl+'/cpd-renewal-payment';
const getRemitaPaymentStatusUrl = rootUrl+'/get-rrr-payment-status';
const saveApplicationTransactionUrl = rootUrl+'/save-cpd-application-transaction';
const applicationRrrStatusUrl = rootUrl+'/cpd-application-rrr';
// Application Urls 
const getPendingApplicationUrl = rootUrl+'/cpd-pending-application';
const updateCpdDataUrl = rootUrl+'/update-cpd-data';
const deleteCpdDataUrl = rootUrl+'/delete-cpd-data';
const submitCpdApplicationUrl = rootUrl+'/submit-cpd-application';
const getCpdApplicationsUrl = rootUrl+'/get-cpd-applications';
const getApplicationUrl = rootUrl+'/get-cpd-application';
const reSubmitApplicationUrl = rootUrl+'/resend-cpd-application';
const getPendingVisitApplicationUrl = rootUrl+'/cpd-pending-visitation';
const getCpdApplicationUrl = rootUrl+'/get-any-cpd-application';
const uploadCpdDocumentsUrl = rootUrl+'/upload-cpd-documents';
const getCpdLettersUrl = rootUrl+'/get-cpd-letters';
const createPendingApplicationUrl = rootUrl+'/create-cpd-pending-application';
const updateApplicationTransactionUrl = rootUrl+'/update-cpd-application-transaction';
// other Urls
const loadingBladeUrl = rootUrl+'/storage/images/loading.gif';
const certificateImageUrl = rootUrl+'/storage/images/certificate.png';
const logoutUserUrl = rootUrl+'/logout';
const blankImageUrl = rootUrl+'/storage/images/blank-photo.png';
const completeImageUrl = rootUrl+'/storage/images/complete.jpeg';
const resetPasswordUrl = rootUrl+'/reset-cpd-password';
const printReceiptUrl = rootUrl+'/print-cpd-receipt';
const perPage = 20;
const recaptchaKey = '6LfIxSUaAAAAAGt9xeD2zcDuZWwcK9lv2Pe6Yu4P';
const websiteUrl = 'https://mdcn.gov.ng';
// Authorization Key
const authorizationToken = '2TcXrFhPN7qIRCEobdeZcYkqZgiSRRmYOwvjce1';
// Remita Urls & Keys
const remitaPaymentUrl ='https://login.remita.net/remita/ecomm/init.reg';
const remitaMerchantId = '632028472';
const remitaApiKey = '361049';

const state = {
    updatePasswordUrl: updatePasswordUrl,
    applicationRrrStatusUrl: applicationRrrStatusUrl,
    saveApplicationTransactionUrl: saveApplicationTransactionUrl,
    getVerificationPaymentUrl: getVerificationPaymentUrl,
    updateApplicationTransactionUrl: updateApplicationTransactionUrl,
    getRemitaPaymentStatusUrl: getRemitaPaymentStatusUrl,
    getTempRenewalPaymentUrl: getTempRenewalPaymentUrl,
    createPendingApplicationUrl: createPendingApplicationUrl,
    getCpdLettersUrl: getCpdLettersUrl,
    uploadCpdDocumentsUrl: uploadCpdDocumentsUrl,
    locationsUrl: locationsUrl,
    deleteCpdDataUrl: deleteCpdDataUrl,
    updateCpdDataUrl: updateCpdDataUrl,
    createSignupUrl: createSignupUrl,
    getSiteParameterUrl: getSiteParameterUrl,
    cpdLoginUrl: cpdLoginUrl,
    cpdProfileUrl: cpdProfileUrl,
    getReAccreditationPaymentUrl: getReAccreditationPaymentUrl,
    generateVerificationRrrUrl: generateVerificationRrrUrl,
    saveReAccreditationTransactionUrl: saveReAccreditationTransactionUrl,
    saveVisitationTransactionUrl: saveVisitationTransactionUrl,
    reReAccreditationRrrStatusUrl: reReAccreditationRrrStatusUrl,
    getPendingApplicationUrl: getPendingApplicationUrl,
    submitCpdApplicationUrl: submitCpdApplicationUrl,
    getCpdNotificationsUrl: getCpdNotificationsUrl,
    uploadCpdPhotoUrl: uploadCpdPhotoUrl,
    getCpdApplicationsUrl: getCpdApplicationsUrl,
    getApplicationUrl: getApplicationUrl,
    reSubmitApplicationUrl: reSubmitApplicationUrl,
    changePasswordUrl: changePasswordUrl,
    getCpdPaymentsUrl: getCpdPaymentsUrl,
    deleteCpdNotificationUrl: deleteCpdNotificationUrl,
    getVisitationPaymentUrl: getVisitationPaymentUrl,
    getPendingVisitApplicationUrl: getPendingVisitApplicationUrl,
    visitationRrrStatusUrl: visitationRrrStatusUrl,
    getCpdApplicationUrl: getCpdApplicationUrl,
    cpdId: localStorage.getItem('cpd_id') || null,
    authorizationToken: authorizationToken,
    perPage: perPage,
    remitaApiKey: remitaApiKey,
    remitaPaymentUrl: remitaPaymentUrl,
    remitaMerchantId: remitaMerchantId,
    rootUrl: rootUrl,
    portalUrl: portalUrl
};

const getters = {
    loadingBladeUrl: () => loadingBladeUrl,
    getLogoutUserUrl: () => logoutUserUrl,
    remitaVerificationCallBackUrl: () => remitaVerificationCallBackUrl,
    remitaVisitationCallBackUrl: () => remitaVisitationCallBackUrl,
    recaptchaKey: () => recaptchaKey,
    blankImageUrl: () => blankImageUrl,
    getResetPasswordUrl: () => resetPasswordUrl,
    getupdatePasswordUrl: () => updatePasswordUrl,
    cpdPhotoUrl: state => state.rootUrl+'/storage/cpd/photo/',
    getAuthorizationToken: state => state.authorizationToken,
    getRootUrl: () => rootUrl,
    websiteUrl: () => websiteUrl,
    printReceiptUrl: () => printReceiptUrl,
    remitaApiKey: state => state.remitaApiKey,
    remitaPaymentUrl: state => state.remitaPaymentUrl,
    remitaMerchantId: state => state.remitaMerchantId,
    completeImageUrl: () => completeImageUrl,
    certificateImageUrl: () => certificateImageUrl,
    remitaReApplicationCallBackUrl: () => remitaReApplicationCallBackUrl,
    remitaReVisitationCallBackUrl: () => remitaReVisitationCallBackUrl,
    remitaRenewalCallbackUrl: () => remitaRenewalCallbackUrl,
    getportalUrl: () => portalUrl
};

const actions = { };

const mutations = { };

export default {
    state,
    getters,
    actions,
    mutations
}