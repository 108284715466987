import axios from 'axios';

const state = {

};

const getters = {
};

const actions = {
    async getVerificationPayment(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getVerificationPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.cpdId}, { headers: header});// make api call
        return response.data;
    },
    async generateVerificationRrr(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.generateVerificationRrrUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async saveApplicationTransaction(state, orderId) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveApplicationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const cpdId = state.rootState.user.cpdId;
        let response = await axios.post(url, { order_id : orderId, id : cpdId }, { headers: header});// make api call
        return response.data;
    },
    async applicationRrrStatus(state, rrr) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.applicationRrrStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const cpdId = state.rootState.user.cpdId;
        let response = await axios.post(url, { rrr : rrr, id : cpdId }, { headers: header});// make api call
        return response.data;
    },
    async getCpdPayments(state, limit) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getCpdPaymentsUrl;
        const cpdId = state.rootState.user.cpdId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: cpdId, limit : limit }, { headers: header});// make api call
        return response.data;
    },
    async getVisitationPayment(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getVisitationPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.cpdId }, { headers: header});// make api call
        return response.data;
    },
    async saveVisitationTransaction(state, orderId) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveVisitationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const cpdId = state.rootState.user.cpdId;
        let response = await axios.post(url, { order_id : orderId, id : cpdId }, { headers: header});// make api call
        return response.data;
    },
    async visitationRrrStatus(state, rrr) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.visitationRrrStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const cpdId = state.rootState.user.cpdId;
        let response = await axios.post(url, { rrr : rrr, id : cpdId }, { headers: header});// make api call
        return response.data;
    },
    async getReAccreditationPayment(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getReAccreditationPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.cpdId }, 
                        { headers: header});// make api call
        return response.data;
    },
    async saveReAccreditationTransaction(state, orderId) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveReAccreditationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const cpdId = state.rootState.user.cpdId;
        let response = await axios.post(url, { order_id : orderId, id : cpdId }, 
                        { headers: header});// make api call
        return response.data;
    },
    async reReAccreditationRrrStatus(state, rrr) {// get application 
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.reReAccreditationRrrStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const cpdId = state.rootState.user.cpdId;
        let response = await axios.post(url, { rrr : rrr, id : cpdId }, 
                        { headers: header});// make api call
        return response.data;
    },
    async getReVisitationPayment(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getVisitationPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.cpdId, type : 're-accreditation' }, 
                        { headers: header});// make api call
        return response.data;
    },
    async saveReVisitationTransaction(state, orderId) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveVisitationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const cpdId = state.rootState.user.cpdId;
        let response = await axios.post(url, { order_id : orderId, id : cpdId, type : 're-accreditation' }, 
                        { headers: header});// make api call
        return response.data;
    },
    async reVisitationRrrStatus(state, rrr) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.visitationRrrStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const cpdId = state.rootState.user.cpdId;
        let response = await axios.post(url, { rrr : rrr, id : cpdId, type : 're-accreditation' }, 
                        { headers: header});// make api call
        return response.data;
    },
    async getTempRenewalPayment(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTempRenewalPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.cpdId }, 
                        { headers: header});// make api call
        return response.data;
    },
    async getRemitaPaymentStatus(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemitaPaymentStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}