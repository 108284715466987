import axios from 'axios';

const perPage = 20;

const state = {
    accessToken: localStorage.getItem('access_token') || null,
    cpdId: localStorage.getItem('cpd_id') || null,
    cpd: {},
    lock: false,
    uploadProgress: null,
    perPage: perPage,
    notifications: {},
    locations: {}
};

const getters = {
    isLoggedIn: state => state.accessToken !== null && state.cpdId !== null ? true : false,
    cpd: state => state.cpd,
    lock: state => state.cpd && (state.cpd.profile_lock == 'yes') ? true : false,
    notifications: state => state.notifications,
    locations: state => state.locations
};

const actions = {
    async createSignup(state, data) {// signup
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createSignupUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    destroyAccessToken(state) {// destroy local storage
        localStorage.removeItem('access_token');// remove token
        localStorage.removeItem('cpd_id');// remove hospital id
        localStorage.removeItem('time_out');// remove time out
        return state.accessToken = null;
    },
    storeAndLoginUser(state, data) {//set token and login user
        let hours = 2;
        localStorage.setItem('access_token', data.access_token);// set access token
        localStorage.setItem('cpd_id', data.cpd_id);// set cpd id
        localStorage.setItem('time_out', new Date().getTime() + (hours * 60 * 60 * 1000));// set timeout
    },
    async cpdLogin(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.cpdLoginUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async fetchCpdProfile(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.cpdProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : state.state.cpdId},
                { headers: header });// make api call
        state.commit('setCpd', response.data.cpd);
        return response.data.cpd;
    },
    async getCpdNotifications(state, limit) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getCpdNotificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : state.state.cpdId, limit : limit }, { headers: header });// make api call
        state.commit('setCpdNotifications', response.data);
        return response.data;
    },
    async getCpdNotification(state, slug) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getCpdNotificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : state.state.cpdId, slug : slug }, { headers: header });// make api call
        return response.data;
    },
    async deleteCpdNotification(state, id) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteCpdNotificationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : id }, { headers: header });// make api call
        return response.data;
    },
    updatePhotoName({ commit }, fileName) {// update photo name
        commit('setUpdatePhotoName', fileName);// set update photo function
    },
    async uploadUserPhoto(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadCpdPhotoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async changePassword(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changePasswordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async getLocations(state) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.locationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        state.commit('setLocations', response.data);
        return response.data;
    },
};

const mutations = {
    setCpd: (state, cpd) => (state.cpd = cpd),// set user profile
    setCpdNotifications(state, data) {// set user notifications
        state.notifications.records = data.notifications.data;
        state.notifications.total = data.total;
    },
    setUpdatePhotoName: (state, fileName) => (state.cpd.photo = fileName),// set user basic profile only
    setLocations: (state, data) => (state.locations = data),// set locations (states, lgas, and countries)
};

export default {
    state,
    getters,
    actions,
    mutations
}