import axios from 'axios';

const state = {

};

const getters = {
};

const actions = {
    async getPendingApplication(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getPendingApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : state.rootState.user.cpdId, type: data }, { headers: header});// make api call
        return response.data;
    },
    async createPendingApplication(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createPendingApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : state.rootState.user.cpdId }, { headers: header});// make api call
        return response.data;
    },
    async updateCpdData(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateCpdDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async deleteCpdData(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteCpdDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async submitCpdApplication(state, id) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.submitCpdApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : id }, { headers: header});// make api call
        return response.data;
    },
    async getCpdApplications(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getCpdApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getApplication(state, id) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: id }, { headers: header});// make api call
        return response.data;
    },
    async reSubmitApplication(state, id) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.reSubmitApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: id }, { headers: header});// make api call
        return response.data;
    },
    async getPaginatedApplicationItems(state, url) {// get paginated applications
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async getPendingVisitApplication(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getPendingVisitApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : state.rootState.user.cpdId}, { headers: header});// make api call
        return response.data;
    },
    async getCpdApplication(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getCpdApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.cpdId }, { headers: header});// make api call
        return response.data;
    },
    async uploadCpdDocuments(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadCpdDocumentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getCpdLetters(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getCpdLettersUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.cpdId }, { headers: header});// make api call
        return response.data;
    },
    async updateApplicationTransaction(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateApplicationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}